import React from 'react';
import './Card.css';
import { themeContext} from '../../Context';
import { useContext } from 'react';

const Card = ({emoji, heading, detail}) => {

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

  return (
    <div className="card" style={{background: darkMode? 'black': ''}}>
        <img src={emoji} alt="" />
        <span>{heading}</span>
        <span>{detail}</span>
        <button className='c-button'>LEARN MORE</button>
    </div>
  )
}

export default Card