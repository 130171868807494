import React from 'react';
import './Works.css';
import Upwork from '../../img/Upwork.png';
import Fiverr from '../../img/fiverr.png';
import Amazon from '../../img/amazon.png';
import Shopify from '../../img/Shopify.png';
import Facebook from '../../img/Facebook.png';
import { themeContext} from '../../Context';
import { useContext } from 'react';
import { motion } from 'framer-motion';
import { Link} from 'react-scroll'



export const Works = () => {
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    
  return (
    <div className="works" id='Work'>
         <div className="awesome">
            <span style={{color: darkMode? 'white': ''}}>Companies I would </span>
            <span>like to work for</span>
            <span>
                Hi! My name is Geisa, I've been studying programming (front-end) for almost a year..
                <br />
                Here's a little of my knowledge, the technologies I know..
                <br />
                I am open to learning new technologies!  
                <br />
                I'm a very dedicated person, I'm very proud to have made it this far alone, 
                <br />
                with a lot of willpower and passion for programming!  
                <br />
                I still don't have experience in the job market, but I would really like a chance 
                <br />
                to show my full potential! 
            </span>
            <Link spy={true} to='Contact' smooth={true} >
                 <button className='button s-button'>Hire me</button>   
            </Link>
                <div className="blur s-blur1" style={{background: '#abf1ff94'}}></div>
        </div>

        <div className="w-right">
            <motion.div 
            initial={{rotate: 45}}
            whileInView={{rotate:0}}
            viewport={{margin: '-40px'}}
            transition={{duration: 3.5, type: 'spring'}}
            className="w-mainCircle">
                <div className="w-secCircle">
                    <img src={Upwork} alt="" />
                </div>
                <div className="w-secCircle">
                    <img src={Fiverr} alt="" />
                </div>
                  <div className="w-secCircle">
                    <img src={Amazon} alt="" />
                </div>
                <div className="w-secCircle">
                    <img src={Shopify} alt="" />
                </div>
                <div className="w-secCircle">
                    <img src={Facebook} alt="" />
                </div>
            </motion.div>

            <div className="w-backCircle blueCircle"></div>
            <div className="w-backCircle yellowCircle"></div>
        </div>
    </div>
  )
}
