import React from 'react';
import './Intro.css';
import Insta from '@iconscout/react-unicons/icons/uil-instagram';
import Linkedin from '@iconscout/react-unicons/icons/uil-linkedin';
import Github from '@iconscout/react-unicons/icons/uil-github';
import girl from '../../img/girl.png';
import thumbup from '../../img/thumbup.png';
import Crown from '../../img/crown.png';
import glassesimoji from '../../img/glassesimoji.png';
import FloatingDiv from '../FloatingDiv/FloatingDiv';
import { themeContext } from '../../Context';
import { useContext } from 'react';
import {motion} from 'framer-motion';
import { Link} from 'react-scroll';



const Intro = () => {

    const transition = {duration: 2, type: 'spring'}

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

  return (
    <div className="intro">
        <div className="i-left">
            <div className="i-name">
                <span style={{color: darkMode? 'white': ''}}>Hi! I Am</span>
                <span>Geisa Santos</span>
                <span>
                    Frontend Developer with high
                    level of experience in development, 
                    <br />
                    producting the
                    Quality work.
                </span>
            </div>
            <Link spy={true} to='Contact' smooth={true} >
              <button className="button i-button">Hire me</button>
            </Link>
            <div className="i-icons">
                <a href='https://github.com/GeisaSantos92' target= '_blank'>
                  <Github color='var(--pink)' size= '3rem'/>
                </a>
                <a href='https://www.linkedin.com/in/geisa-santos-93568a249/' target='_blank'>
                <Linkedin color='var(--pink)' size= '3rem'/>
                </a>
                <a href='https://www.instagram.com/geisasouza92/' target='_blank'>
                     <Insta color='var(--pink)' size= '3rem'/>
                </a>
            </div>
        </div>
        <div className="i-right">
          
            <img src={girl} alt="" />
            <motion.img
            initial={{left: '-36%'}}
            whileInView={{left: '-18%'}}
            transition = {transition}
            src={glassesimoji} alt="" />
       <motion.div 
        initial={{top: '-4%', left: '74%'}}
        whileInView={{left: '68%'}}
        transiton={transition}
        style={{top: '-4%', left: '68%'}}
        className= 'floating-div'>
            <FloatingDiv image={Crown} txt1='Web' txt2='Developer'/>
        </motion.div>

        <motion.div
         initial={{top: '18rem', left: '9rem'}}
         whileInView={{left: '0rem'}}
         transiton={transition}
         style={{top:'18rem', left: '0rem'}}
         className= 'floating-div'>
           
            <FloatingDiv image={thumbup} txt1='Passionate about ' txt2='technology'/>
        </motion.div>
        
        <div className="blur" 
        style={{background: 'rgb(238 210 255)' }}></div>

        <div className="blur" 
        style={{
            background: '#C1F5FF',
            top: '17rem',
            width: '21rem',
            height: '11rem',
            left: '-9rem'
        }}></div>
        </div>
        
      </div>
  )
}

export default Intro