import React from 'react';
import './Services.css';
import HeartEmoji from '../../img/heartemoji.png';
import Glasses from '../../img/glasses.png';
import Humble from '../../img/humble.png';
import Card from '../Card/Card';
import Resume from './resume.pdf';
import { themeContext} from '../../Context';
import { useContext } from 'react';
import { motion } from 'framer-motion';


const Services = () => {

    const transition = {duration: 1, type: 'spring'}

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    
  return (
    <div className="services" id='Services'>
        <div className="awesome">
            <span style={{color: darkMode? 'white' : ''}}>My Awesome</span>
            <span>Services</span>
            <span>These are the technologies I work with! 
                <br />
                And you can download my resume.
            </span>
            <a href={Resume} download>
                 <button className='button s-button'>Dowload CV</button>
            </a>
            
            <div className="blur s-blur1" style={{background: '#abf1ff94'}}></div>
        </div>
        <div className="cards">
            <motion.div 
            initial={{left: '25rem'}}
            whileInView={{left: '14rem'}}
            transition={transition}
            style={{left: '14rem'}}>
                <Card
                emoji={HeartEmoji}
                heading='Developer'
                detail='HTML, CSS'
                />
            </motion.div>

            <motion.div 
            initial={{left: '25rem'}}
            whileInView={{left: '-4rem'}}
            transition={transition}
            style={{top:'12rem', left: '-4rem'}}>
                <Card
                emoji={Glasses}
                heading={'Developer'}
                detail={'Javascript, React JS'}
                />
            </motion.div>

            <motion.div 
            initial={{left: '25rem'}}
            whileInView={{left: '12rem'}}
            transition={transition}
            style={{top: '19rem', left: '12rem'}}>
                <Card
                emoji={Humble}
                heading={'Developer'}
                detail={'Typescript, Github'}
                />
            </motion.div>
            <div className="blur s-blur2" style={{background: 'car(--purple)'}}></div>
        </div>
    </div>
  )
}

export default Services