import React from 'react';
import './Footer.css';
import Wave from '../../img/wave.png';
import Insta from '@iconscout/react-unicons/icons/uil-instagram';
import Facebook from '@iconscout/react-unicons/icons/uil-facebook';
import Github from '@iconscout/react-unicons/icons/uil-github';

export const Footer = () => {
  return (
    <div className="footer">
        
        <div className="f-content">
            <span>geisa.santos92@icloud.com</span>
            <div className="f-icons">
            <a href='https://github.com/GeisaSantos92' target= '_blank'>
                <Github color='white' size= '3rem'/>
            </a>
            <a href='https://www.instagram.com/geisasouza92/' target='_blank'>
                <Insta color='white' size= '3rem'/>
            </a>
            <a href='https://www.facebook.com/geisa.santos.505523' target='_blank'>
                <Facebook color='white' size= '3rem'/>
            </a>
                
                
               

            </div>
        </div>
    </div>
  )
}
