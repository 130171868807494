import React from 'react';
import './Portfolio.css';
import {Swiper, SwiperSlide} from 'swiper/react';
import miniblog from '../../img/miniblog.png';
import gallery from '../../img/gallery.png';
import fitness from '../../img/fitness.png';
import calculator from '../../img/calculator.png';
import memory from '../../img/memory.png';
import finances from '../../img/finances.png';
import 'swiper/css';
import { themeContext} from '../../Context';
import { useContext } from 'react';


export const Portfolio = () => {

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

  return (
    <div className="portfolio" id='Portfolio'>
        <span style={{color: darkMode? 'white' : ''}}>Recent Projects</span>
        <span>Portfolio</span>

        <Swiper
        spaceBetween={30}
        slidesPerView={3}
        grabCursor={true}
        className='portfolio-slider'
        >
            <SwiperSlide>
                <img src={finances} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={gallery} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={fitness} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={miniblog} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={memory} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={calculator} alt="" />
            </SwiperSlide>
        </Swiper>
    </div>
  )
}
